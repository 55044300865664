import React from 'react';

const GiftCard = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="ticket-discount" transform="translate(-748 -572)">
        <path id="Vector" d="M15.75,17.5h-10C1.34,17.5,0,16.16,0,11.75v-.5a.755.755,0,0,1,.75-.75A1.75,1.75,0,0,0,.75,7,.755.755,0,0,1,0,6.25v-.5C0,1.34,1.34,0,5.75,0h10c4.41,0,5.75,1.34,5.75,5.75v1a.755.755,0,0,1-.75.75,1.75,1.75,0,0,0,0,3.5.755.755,0,0,1,.75.75C21.5,16.16,20.16,17.5,15.75,17.5ZM1.5,11.91C1.52,15.35,2.23,16,5.75,16h10c3.34,0,4.15-.59,4.24-3.59A3.249,3.249,0,0,1,20,6.09V5.75c0-3.57-.68-4.25-4.25-4.25h-10c-3.52,0-4.23.65-4.25,4.09a3.247,3.247,0,0,1,0,6.32Z" transform="translate(749.25 575.25)" fill="CurrentColor" />
        <path id="Vector-2" data-name="Vector" d="M1.01,2A1,1,0,1,1,2,1,.988.988,0,0,1,1.01,2Z" transform="translate(761.99 585.88)" fill="CurrentColor" />
        <path id="Vector-3" data-name="Vector" d="M1.01,2A1,1,0,1,1,2,1,.988.988,0,0,1,1.01,2Z" transform="translate(755.99 580.88)" fill="CurrentColor" />
        <path id="Vector-4" data-name="Vector" d="M.747,8.227a.742.742,0,0,1-.53-.22.754.754,0,0,1,0-1.06L6.947.218a.75.75,0,0,1,1.06,1.06l-6.73,6.73A.725.725,0,0,1,.747,8.227Z" transform="translate(755.883 580.203)" fill="CurrentColor" />
        <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(748 572)" fill="none" opacity="0" />
      </g>
    </svg>

  );
};

export default GiftCard;
