import React from 'react';

const Flyer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="vuesax_outline_note-2" data-name="vuesax/outline/note-2" transform="translate(-108 -252)">
        <g id="note-2">
          <path id="Vector" d="M9.593,18.429a7.881,7.881,0,0,1-.82-.04,10.841,10.841,0,0,1-1.72-.29l-1.68-.4c-4.61-1.09-6.12-3.53-5.04-8.13l.98-4.19a10.628,10.628,0,0,1,.8-2.36c1.72-3.55,5.01-3.23,7.35-2.68l1.67.39c2.34.55,3.82,1.42,4.65,2.75s.95,3.04.4,5.38l-.98,4.18C14.343,16.7,12.553,18.429,9.593,18.429ZM6.9,1.5a3.457,3.457,0,0,0-3.44,2.17,10.165,10.165,0,0,0-.69,2.05l-.98,4.19c-.89,3.78.14,5.43,3.92,6.33l1.68.4a10.3,10.3,0,0,0,1.51.25c2.72.27,4.07-.92,4.83-4.19l.98-4.18c.45-1.93.39-3.28-.21-4.25s-1.78-1.63-3.72-2.08L9.113,1.8A9.587,9.587,0,0,0,6.9,1.5Z" transform="translate(114.217 253.751)" fill="CurrentColor" />
          <path id="Vector-2" data-name="Vector" d="M7.095,17.9c-2.57,0-4.21-1.54-5.26-4.79L.555,9.156c-1.42-4.4-.15-6.88,4.23-8.3l1.58-.51a10.422,10.422,0,0,1,1.26-.33.724.724,0,0,1,.74.28.776.776,0,0,1,.08.79,9.32,9.32,0,0,0-.68,2.04l-.98,4.19c-.89,3.78.14,5.43,3.92,6.33l1.68.4a10.3,10.3,0,0,0,1.51.25.769.769,0,0,1,.67.56.752.752,0,0,1-.3.81,9.891,9.891,0,0,1-2.54,1.17l-1.58.52A10.288,10.288,0,0,1,7.095,17.9Zm-.55-16.03-1.29.42c-3.57,1.15-4.42,2.83-3.27,6.41l1.28,3.95c1.16,3.57,2.84,4.43,6.41,3.28l1.58-.52c.06-.02.11-.04.17-.06l-1.06-.25c-4.61-1.09-6.12-3.53-5.04-8.13l.98-4.19C6.375,2.456,6.455,2.146,6.545,1.866Z" transform="translate(109.235 256.354)" fill="CurrentColor" />
          <path id="Vector-3" data-name="Vector" d="M5.6,2.723a1.366,1.366,0,0,1-.19-.02L.563,1.473A.748.748,0,0,1,.933.023l4.85,1.23a.746.746,0,0,1-.18,1.47Z" transform="translate(119.887 259.787)" fill="CurrentColor" />
          <path id="Vector-4" data-name="Vector" d="M3.663,2.233a1.366,1.366,0,0,1-.19-.02l-2.91-.74A.748.748,0,1,1,.933.023l2.91.74a.749.749,0,0,1,.54.91A.728.728,0,0,1,3.663,2.233Z" transform="translate(118.897 263.657)" fill="CurrentColor" />
          <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(108 252)" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};

export default Flyer;
